import React from 'react'
import { Link } from 'react-router-dom'
function Boots() {
  return (
   <>
   <div className="Footer">
                
                <div className="container-fluid w-100% h-220px border rounded text-center	text-white bg-slate-800">
                    <div className="row">

                    <div className="col-md-6 col-lg-3 col-12 ft-2 mb-2 text-center ss">
                           <h5 className='text-2xl font-semibold mt-2 underline-offset-2 mm text-yellow-400 underline'>Quik Links</h5>
                            <ul className='mt-2 font-medium	'>
                                <li className="nav-item">
                                    <Link className="mt-0.5 text-sm" to='/'><i class="fa-solid fa-house text-yellow-400 ml-3"></i><span>  </span>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="mt-0.5 text-sm ml-3" to='/about'><i class="fa-solid fa-address-card text-yellow-400"></i> <span> </span>About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="mt-0.5 text-sm ml-3.5" to='/contact'><i class="fa-solid fa-address-book text-yellow-400"></i> <span>  </span>Contact</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="mt-0.5 text-sm ml-4" to='/service'><i class="fa-brands fa-product-hunt text-yellow-400"></i> <span>  </span>Products</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="mt-0.5 text-sm ml-4" to='/distributors'><i class="fa-solid fa-cart-shopping  text-yellow-400"></i><span>  </span>Distributors</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-6 col-lg-5 col-12 ft-1 text-centers ss">
                            <h3 className='text-2xl  font-semibold mt-2 text-yellow-400 underline'><span className='text-blue-400'>Purifte</span>Water</h3>
                            <p className='text-sm font-medium mt-4  text-color text-green-300'><span className='text-red-400'>Efficiently filters contaminants for </span>crystal-clear hydration
                            <p className='text-sm font-medium  mt-3 '>Advanced purification for pristine, safe drinking water
                            </p>
                            <p className='text-sm font-medium  mt-3'>Mineral Water Supply Service For SchoolMineral Water.</p>
                            </p>
                           
                           
                            <div className="footer-icons  mt-5 text-2xl px-5 kk ">
                               <a href="https://www.facebook.com/profile.php?id=61553860352752"><i class="fa-brands fa-facebook px-3 text-blue-300 "></i></a>
                                <a href="https://twitter.com/Purifitewater"><i class="fa-brands fa-twitter px-3 text-blue-500"></i></a>
                                <a href="https://www.instagram.com/purifite_pvt_ltd/"><i class="fa-brands fa-instagram px-3 text-red-600"></i></a>
                                <a href=""><i class="fa-brands fa-linkedin-in px-3 text-orange-700"></i></a>
                            </div>
                       
                        </div>

                        <div className="col-md-6 col-lg-4 col-12 ft-3 text-center ss relative">
                            <h5 className='text-2xl font-medium mt-1 text-center  text-yellow-400 underline'><span className='text-blue-400'>Contact</span>information</h5>
                            <p className='text-sm	font-medium	mt-3 mr-20'><i class="fa-solid fa-mobile text-red-600"></i><span></span> +91 9639003009</p>
                            <p className='text-sm	font-medium	mt-3 mr-20'><i class="fa-solid fa-mobile text-green-600"></i><span></span> +91 9639003009</p>
                            <p className='text-sm	font-medium	mt-3 ml-2'><i class="fa-solid fa-envelope text-yellow-400"></i><span> </span>waleedishfaq1515@gmail.com</p>
                            <p className='text-sm   font-medium	mt-3 ml-5'><i class="fa-solid fa-location-dot text-blue-500"></i><span> </span>Address - Spaze it park, Sector 49</p>
                            <p className='text-sm   font-medium	mt-3 mr-5'>Gurugram, Haryana 122018</p>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
   </>
  )
}

export default Boots