import React from "react";
import r3 from    "../../rr.png"
import w3 from    "../../w5.png"
import { Button } from 'flowbite-react';
import Owl from "../../Owl"
import Cardd from "../../Cardd";
import "./Bottle.css"
export const About = () => {
  return (
    <>
    <div className="container-fluid dl">
    <div class="row featurette mt-5  shadow">
      
      <div class="col-md-7 mt-5">
        <h1 class="featurette-heading text-3xl text-center mt-5 font-semibold">RO Service Pro: Your Ultimate Solution for Reliable Water Purification </h1>
        <p class=" mt-5 font-normal text-left">Welcome to Purifite, where purity meets refreshment. Headquartered in Gurgaon, we continue to aggressively adopt new strategies and innovative initiatives in order to connect with our consumers & emerge as the fastest growing Beverage company. As a leading provider of premium drinking water, we are dedicated to delivering the highest quality hydration to our customers. Our commitment to excellence, environmental responsibility, and customer satisfaction sets us apart in the industry. From sourcing the finest water to our advanced purification methods, we ensure that every drop that reaches you meets or exceeds the highest industry standards.</p>
      </div>
      <div class="col-md-5 mt-5">
        <img src={w3}></img>

      </div>
    </div>
    <div class="row featurette mt-5  shadow">
      <div class="col-md-5 mt-5">
        <img src={r3}></img>

      </div>
      <div class="col-md-7 mt-5">
        <h2 class="featurette-heading text-2xl mt-5 font-normal">Water Ken: Your Trusted Service Provider for All Water Needs.</h2>
        <p class=" mt-5 text-1x1 text-left font-normal">Here at Purifite, refreshment and purity come together. With our main office located in Gurgaon, we never stop implementing fresh approaches and creative projects to engage with our customers and become the beverage business with the quickest pace of growth. As a top supplier of premium drinking water, we're committed to giving our clients the best possible hydration. We stand out in the business for our dedication to quality, environmental responsibility, and customer happiness. Every drop of water that reaches you will either meet or surpass the highest industry standards thanks to our sophisticated purification processes and careful selection of the best water.</p>
        <Button gradientMonochrome="success" className='hover:-translate-y-2 transition-all duration-300 mt-5'>Learn more</Button>
      </div>
      
    </div>
    </div>
    <Cardd/>
    <Owl/>
    </>
  );
};
