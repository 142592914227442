import React from 'react'
import { Carousel } from 'flowbite-react';
import s1 from "./Purifitee.png"
import s2 from "./slide2.png"
import s3 from "./slide3.png"
import "./Crousel.css";
function Crousel() {
  return (
    <>
     <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 ">
      <Carousel leftControl="" rightControl="">
      <img src={s1} alt="..." />
        <img src={s2} alt="..." />
        <img src={s3} alt="..." />
       
      </Carousel>
    </div>
    </>
    
  )
}

export default Crousel