import React from "react";
import { Button } from 'flowbite-react';
import ss from    "../../14.png"
import sw from    "../../12.png"
import Product from "./Product";
import Cardd from "../../Cardd"

export const Blog = () => {
  return (
   <>
   
    <div className='shadow container'>
   <div className='px-4 lg:px-14  max-w-screen-2xl mx-auto my-8 '>
    <div className='md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12'>
      <div className='md:w-3/5 mx-auto'>
      <h2 className='text-4xl text-neutralDGrey font-semibold mb-4 md:w-4/5'>
      Best filter water Service Provider. 
          </h2>
          <p className='md:w-3/4  text-neutralDGrey mb-8 text-2x1 text-left'>
          In addition to our exceptional RO services, we offer cutting-edge filter water solutions that cater to specific water quality concerns. Our filters are designed to target and eliminate a wide range of impurities, leaving you with water that not only tastes great but is also free from harmful elements. </p>
          <Button gradientMonochrome="success" className='hover:-translate-y-2 transition-all duration-300'>Learn more</Button>
      </div>
      <div>
           <img src={ss}></img>
        </div>
    </div>
   </div>
   <div className='px-4 lg:px-14  max-w-screen-2xl mx-auto bg-neutralSilver py-16'>
        <div className='flex flex-col md:flex-row justify-between gap-8'>
            <div className='md:w-1/3 '>
               <img src={sw}></img>
            </div>
            <div className='md:w-2/3 mx-auto '>
            <h2 className='text-4xl text-neutralDGrey font-semibold mb-4 md:w-4/5'>
           Best Ro Service Provider in Gurugram - Haryana
          </h2>
          
                <p className='md:w-4/5 text-2x1 text-neutralDGrey mb-8 leading-7 text-left '>  we offer cutting-edge filter water solutions that cater to specific water quality concerns. Our filters are designed to target and eliminate a wide range of impurities, leaving you with water that not only tastes great but is also free from harmful elements.

                At Purifite, we prioritize your health and well-being. Our SEO-friendly services are aimed at optimizing your water quality, so you can enjoy the benefits of pure and refreshing water every day. Explore our range of RO services and filter water solutions to make a positive impact on your hydration experience. Trust us to deliver excellence in water purification – your journey to cleaner, healthier water starts with us.
                </p>
                <Button gradientMonochrome="success" className='hover:-translate-y-2 transition-all duration-300 '>Learn more</Button>
            </div>
        </div>
    </div>
    
   </div>
   <Product/>
   <Cardd/>
   </>
  );
};
