import React from "react";
import { Button } from 'flowbite-react';
import Product from "./Product";

import Bottle from "./Bottle";
import Newc from "./Newc";
export const Home = () => {
  return (
    
    <>
     <Product/>
    <Newc/>
    <Bottle/>
   
    </>
  );
};
