import React from "react";
import "./Article.css";
function Article() {
  return (
    <>
      <div>
        <div className="container align-middle mb-5 rounded justify-items-center	">
          <div class="row featurette mt-5  shadow rounded	">
            <h1 class="featurette-heading text-2xl text-center mt-5 font-semibold font-sans	">
              Purity of Life: Benefits of RO Water
            </h1>
            <p class="lead mt-3 font-normal	justify-items-center		">
              In a world where health is wealth, the importance of clean and
              safe drinking water cannot be overstated. As a leading purifying
              company committed to ensuring the well-being of our communities,
              we feel a responsibility to educate and inspire individuals about
              the benefits of Reverse Osmosis (RO) water. Join us on a journey
              to discover the purity of life through the lens of RO water and
              the transformative impact it can have on your health and overall
              well-being.
            </p>

            <h2 class="featurette-heading text-2xl text-center mt-3 font-semibold font-sans	">
              Essence of RO Water:
            </h2>

            <p class="lead mt-3 font-normal">
              Reverse Osmosis is a state-of-the-art water purification
              technology that utilizes a semi-permeable membrane to remove
              impurities, contaminants, and pollutants from water. The process
              involves applying pressure to force water molecules through the
              membrane, leaving behind a crystal-clear elixir of health. The
              result? Water that is not just free of harmful substances but also
              enriched with essential minerals, ensuring every sip is a step
              towards a healthier you.
            </p>

            <h2 class="featurette-heading text-2xl text-center mt-3 font-semibold font-sans	">
              Health Benefits:
            </h2>

            <p class="lead mt-3 font-normal">
              1. Purity Redefined: RO water eliminates contaminants such as
              bacteria, viruses, heavy metals, and chemicals, providing a level
              of purity that standard tap water or other filtration methods may
              not achieve. This translates into a water source that is not just
              safe but pure at its core.
            </p>
            <p class="lead mt-3 font-normal">
              2. Mineral Enrichment: While removing impurities, RO systems
              ensure that essential minerals like calcium and magnesium are
              retained in the water. These minerals are crucial for maintaining
              bone health, supporting the nervous system, and promoting overall
              vitality.
            </p>
            <p class="lead mt-3 font-normal">
              3. Better Hydration: The purified nature of RO water enhances its
              taste, making it more palatable and encouraging increased water
              consumption. Staying properly hydrated is essential for
              maintaining bodily functions, supporting metabolism, and ensuring
              radiant skin.
            </p>
            <h2 class="featurette-heading text-2xl text-center mt-3 font-semibold font-sans	">
              Environmental Responsibility:
            </h2>
            <p class="lead mt-3 font-normal">
              At Purifite, we understand the importance of prioritising human
              health and being stewards of the environment. RO water systems
              produce less wastewater compared to other purification methods,
              making them more environmentally friendly. By choosing RO water,
              you contribute to a sustainable future, reducing your ecological
              footprint.
            </p>
            <p class="lead mt-3 font-normal"></p>

            <h2 class="featurette-heading text-2xl text-center  font-semibold font-sans	">
              Empowering Communities:
            </h2>

            <p class="lead mt-3 font-normal mb-5">
              Access to clean water is a basic human right, and we believe in
              empowering communities by making RO water technology accessible.
              Whether in urban centres or remote areas, our commitment extends
              to providing reliable, affordable, and sustainable solutions,
              ensuring that everyone can experience the transformative benefits
              of pure water.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Article;
