import "./App.css";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Blog } from "./components/Pages/Blog";
import { Contact } from "./components/Pages/Contact";
import Crousel from "./Crousel";

import Boots from "./components/Pages/Boots";

import Tophero from "./Tophero";
import Distributors from "./components/Pages/Distributors";
import Article from "./components/Pages/Article";


function App() {
  return (
    <>
      <Router>
        <Tophero/>
        <NavBar/>
        <Crousel/>
        
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/distributors" element={<Distributors />} />
            <Route path="/article" element={<Article/>} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        
        
        
        <Boots/>
        
      </Router>
  </>
  );
}

export default App;
