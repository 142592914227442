import React from 'react'
import "slick-carousel/slick/slick.css"; 
import './Owl.css'
import a1 from "./x.png"
import a2 from "./y.png"
import a3 from "./z.png"
import a4 from "./w.png"
import a5 from "./lk.png"
import q2 from "./q2.png"
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };




function Owl() {
  return (
    <>
    <div className='crousel container text-center'>
        <h2 className='ee mb-4 tesxt-semibold  text-center  text-2x1 '>Our Team </h2>
        <Slider {...settings}>
          <div className='box'>
           <div className='text-center'>
            <img src={a1} alt="" />
            </div>
                <div>
                    <h2 className='text-center'>Jitendra </h2>
                    <p className='text-center'> Company Owner</p>
                </div>
          </div>
          
          <div className='box text-center'>
           <div>
          <img src={a2} alt="" />
          </div>
          <div>
                    <h2 className='text-center'>Tarun </h2>
                    <p className='text-center'> Company member</p>
                </div>
          </div>
          <div className='box text-center'>
           <div>
          <img src={a3} alt="" />
          </div>
         <div>
         <h2>Pratush</h2>
                    <p>Company member  </p>
         </div>
          </div>
          
          <div className='box text-center'>
            <div>
          <img src={a4} alt="" />
          </div>
         <div>
         <h2>Rajdeep</h2>
                   
         </div>
          </div>
          <div className='box text-center'>
            
          <img src="" alt="" />
         <dir>
         
         </dir>
          </div>
          <div className='box text-center'>
           <img src="" alt="" />
         <div>
         <h2></h2>
                    
         </div>
          </div>
          <div className='box  text-center'>
            <img src="" alt="" />
         <div>
         <h2></h2>
                    <p></p>
         </div>
          </div>
          <div className='box'>
           
          <img src="" alt="" />
          <h2></h2>
                    <p></p>
          </div>
        </Slider>
      </div>
    
    

    </>
  )
}

export default Owl