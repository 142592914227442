import React from "react";
import r1 from "../../r1.png";
import r2 from "../../r2.png";
import r3 from "../../r3.png";
import r4 from "../../r4.png";
import r5 from "../../r5.png";
import w1 from "../../w1.png";
import w2 from "../../w2.png";
import w3 from "../../w3.png";
import w4 from "../../w4.png";
function Product() {
  return (
    <>
      <div class="container mb-5 shadow cursor-pointer	">
        <h1 className="text-center text-2xl	font-semibold mt-5 h-5 mb-5">
          Pure Water Pleasure: Ro Sell
        </h1>

        <div class="row justify-content-center text-center items mt-8  ">
          <div class="col-12 col-md-6 col-lg-3 item hover:-translate-y-2 transition-all duration-300">
            <div class="card no-hover uu shadow rounded">
              <img src={w1} alt="" />
              <h4 className="text-2xl mb-4 font-normal">
                Purifite 20 Litre Water Can
              </h4>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item rounded hover:-translate-y-2 transition-all duration-300	">
            <div class="card no-hover uu shadow">
              <img src={w2} alt="" />
              <h4 className="text-2xl mb-4 font-normal">
                Purifite 10 Litre Water Can
              </h4>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item rounded hover:-translate-y-2 transition-all duration-300	">
            <div class="card no-hover uu shadow">
              <img src={w3} alt="" />
              <h4 className="text-2xl mb-4 font-normal">
                Purifite 1 Litre Water Can
              </h4>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item rounded	hover:-translate-y-2 transition-all duration-300">
            <div class="card no-hover uu shadow">
              <img src={w4} alt="" />
              <h4 className="text-2xl mb-4 font-normal">
                Purifite 20 Litre Water Can
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="container mb-10 shadow mt-5 rounded	cursor-pointer	">
        <h1 className="text-center text-2xl	font-semibold mt-5 h-5 mb-5">
          Clean Sips Await: FilterWater
        </h1>

        <div class="row justify-content-center text-center items mt-5 rounded	">
          <div class="col-12 col-md-6 col-lg-3 item hover:-translate-y-2 transition-all duration-300">
            <div class="card no-hover uu shadow ">
              <img src={r1} alt="" />
              <h4 className="text-2xl mb-4 font-normal">Coming Soon</h4>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item rounded	hover:-translate-y-2 transition-all duration-300">
            <div class="card no-hover uu shadow">
              <img src={r2} alt="" />
              <h4 className="text-2xl mb-4 font-normal">Coming Soon</h4>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item rounded	hover:-translate-y-2 transition-all duration-300">
            <div class="card no-hover uu shadow">
              <img src={r3} alt="" />
              <h4 className="text-2xl mb-4 font-normal">Coming Soon</h4>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item rounded	hover:-translate-y-2 transition-all duration-300">
            <div class="card no-hover uu shadow">
              <img src={r4} alt="" />
              <h4 className="text-2xl mb-4 font-normal">Coming Soon</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
