import React from "react";
import "../../React-responsive-navbar-main/src/components/Pages/Ds.css";
function Cardd() {
  return (
    <>
      <div class="container mb-10 shadow ff">
        <div className="text-center mt-10">
          <h1 className="text-3xl font-semibold">Our Vision</h1>
        </div>

        <div class="row justify-content-center text-center items mt-5 dd py-2">
          <div class="col-12 col-md-6 col-lg-4 item rounded-md  cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all">
            <div class="card no-hover bg-blue-400  text-white ml">
              <h4 class="about-mission text-2xl font-semibold mt-5">
                Our Mission
              </h4>
              <p className="mt-3 mb-5">
                There are many variations of passages of avaialable but the
                majority have in some form, by injected humou or words which
                don't look even slightly believable. There are many variations
                of but the majority have suffered.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 item rounded-md  cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all">
            <div class="card no-hover bg-blue-400 text-white ml">
              <h4 class="about-mission text-2xl font-semibold mt-5">
                Our Vision
              </h4>
              <p className="mt-3 mb-5">
                Purifte water and RO Pvt. Ltd. vision is to see further, to seek
                out competitive advantages for our clients through the
                innovative use of technology; and to help them achieve long-term
                success and prosperity and to help .
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 item rounded-md  cursor-pointer hover:-translate-y-5 hover:border-b-4 hover:border-indigo-700 transition-all">
            <div class="card no-hover bg-blue-400  text-white ml">
              <h4 class="about-mission text-2xl font-semibold mt-5">
                Our History
              </h4>
              <p className="mt-3 mb-5">
                There are many variations of passages of avaialable but the
                majority have in some form, by injected humou or words which
                don't look even slightly believable. There are many variations
                of but the majority have suffered.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cardd;
