import React from 'react'
import "./Bottle.css";
import w1 from "../../w1.png"
import w2 from "../../w2.png"
import w3 from "../../w3.png"
import w4 from "../../w4.png"
import w5 from "../../w5.png"
import w6 from "../../w3.png"
function Bottle() {
  return (
    <>
    <div className='container align-middle mb-5 rounded	'>
    <div class="row featurette mt-5  shadow rounded	">
      
        <h1 class="featurette-heading text-2xl text-center  font-semibold font-sans	">RO Service Pro: Your Ultimate Solution for Reliable Water Purification</h1>
        <p class="lead mt-5 font-normal		">Welcome to Purified, where purity meets refreshment. Headquartered in Gurgaon, we continue to aggressively adopt new strategies and innovative initiatives in order to connect with our consumers & emerge as the fastest growing Beverage company. As a leading provider of premium drinking water, we are dedicated to delivering the highest quality hydration to our customers. Our commitment to excellence, environmental responsibility, and customer satisfaction sets us apart in the industry. From sourcing the finest water to our advanced purification methods, we ensure that every drop that reaches you meets or exceeds the highest industry standards.</p>
        <p class="lead mt-5 font-normal		">Experience pure and crystal-clear water with our top-tier RO water supplier, setting the benchmark for excellence in water purification. Our commitment to delivering the highest quality water ensures that you and your loved ones have access to a safe and refreshing drinking experience.</p>

         <p class="lead mt-5 font-normal		">At the heart of our service is state-of-the-art reverse osmosis (RO) technology, a cutting-edge process that eliminates impurities, contaminants, and toxins from your water supply. Our RO systems boast advanced filtration capabilities, removing particles as small as individual molecules to provide water that surpasses industry standards. This meticulous purification process guarantees not only the removal of common pollutants but also the retention of essential minerals, ensuring a balanced and healthful drinking water.</p> 
          <p class="lead mt-5 font-normal		mb-5">What sets us apart is our unwavering dedication to quality assurance. Rigorous testing protocols and regular maintenance of our RO systems guarantee that every drop of water meets the highest purity standards. Our commitment to environmental sustainability is reflected in our eco-friendly approach to water purification. We strive to minimize waste, reduce energy consumption, and employ eco-conscious practices throughout our operations.</p>
      </div>
    </div>
    </>
  )
}

export default Bottle