import React from 'react'
import "./Newc.css";
import ss from    "../../1.png"
import sw from    "../../667.png"
function Newc() {
  return (
    <>
   <div className="div1">

<h1>
    Our brands, your trust
</h1>
<p>
    A household name for decades gone, decades to come.
</p>

<div className="div2">
    <h1>Purifite</h1>

</div>

<div className="div3 ">
 <img src={ss} alt=""/>
     
</div>
<div className="div4 ">
    <img src={ss} alt=""/>   
</div>
<div className="div5 ">
    <img src={ss} alt=""/>  
</div>
<div className="div6 ">
  <img src="https://www.bisleri.com/images/home-banner-slider/our-brand-copy.webp"  alt=""/>
</div>
<div className="div7 ">
    <h2>
        Values Quality with Care</h2> 

        <p>Purifite Mineral Water distinguishes itself with a commitment to quality upheld through a meticulous 10-step quality process and 114 rigorous tests. Each step of this process ensures that every drop of water is not only safe but also pure and hygienic to the core.</p>
        

        <p>The water undergoes thorough scrutiny at each stage of the process, leaving no room for compromise. This dedication to quality extends beyond internal standards, as Purifite Mineral Water also adheres to the guidelines set by the Bureau of Indian Standards (BIS).</p>
        
        
        <p>Through transparency and adherence to strict protocols, Purifite instills confidence in its consumers, assuring them that every sip they take is not only refreshing but also free from any contaminants or impurities. This assurance of quality is backed by a</p>
            
</div>

<div className="div8">
    <img src={sw} alt=""/>
</div>
</div> 
    
    
    </>
  )
}

export default Newc