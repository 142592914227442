import React from "react";
import "./Tophero.css";
import { FaCity } from "react-icons/fa6";


function Tophero() {
  return (
    <>
      <div className="tophero container-fluid sticky-top ">
        <div className="right">9639003009</div>

          <div>
          
          </div>
        {/* <div className="middle click">
          <div className="button bg-white mt-9"><FaCity/></div>
           <div className="dropdown">
            <a href="">Uttarpra.</a>
            <a href="">Delhi</a>
            <a href="">Bihar</a>
          </div>
        </div> */}

        <div className="left">waleedishfaq1515@gmail.com</div>
      </div>
    </>
  );
}

export default Tophero;
