import React from 'react'

function Drop() {
  return (
    <>
    
    
    <div className="btn-group ">
  <button type="button" className="btn btn-primary dropdown-toggle text-center" data-bs-toggle="dropdown" aria-expanded="true">
    City..
  </button>
  <ul className="dropdown-menu">
    <li><a className="dropdown-item" href="#">Delhi</a></li>
    <li><a className="dropdown-item" href="#">Noida</a></li>
    <li><a className="dropdown-item" href="#">Gurugram</a></li>
    <li><hr className="dropdown-divider"/></li>
    <li><a className="dropdown-item" href="#">Aligarh</a></li>
  </ul>
</div>

    </>
  )
}

export default Drop